<template>
	<div class="home">
		<h1>扫码支付</h1>
		<div class="qrcode-content">
			<div id="qrcode" ref="qrCodeDiv" class="qrcode"></div>
			<a @click="refresh">刷新</a>
		</div>
		<p>每隔一分钟刷新一次</p>
	</div>
</template>

<script>
	import dd from 'gdt-jsapi';
	import aplus_queue from 'aplus_queue'
	import axios from 'axios';
	import QRCode from 'qrcodejs2';
	export default {
		name: 'HomeView',
		components: {},
		data() {
			return {
				isFirst: true,
				ctx: "https://sydw.app.saiyun.net/"
			};
		},
		created() {
			dd.ready(() =>{
				dd.getAuthCode({
					//tenantId租户Id
					corpId: "42420"
				}).then(result =>{
					if(result){
						let authCode = result.code;
						if (authCode == null) {
							//pc端返回值
							authCode = result.auth_code;
						}
						//获取用户
						this.getUser(authCode);
						//每分钟自动刷新
						setInterval(this.refresh,60000);
					}else{
						console.log("get authCode error=" + JSON.stringify(result));
					}
				})
			});
		},
		mounted() {
			//自适应
			let documentElement = document.documentElement;

			function callback() {
				let clientWidth = documentElement.clientWidth;
				clientWidth = clientWidth < 780 ? clientWidth : 780;
				documentElement.style.fontSize = clientWidth / 10 + 'px';
			}
			document.addEventListener('DOMContentLoaded', callback);
			window.addEventListener('orientationchange' in window ? 'orientationchange' : 'resize', callback);
			// //稳定性监控
			// try {
			// 	const config = {
			// 		bid: 'jgdwfkm_zzdpro',
			// 		signkey: '1234567890abcdef',
			// 		gateway: 'https://wpk-gate-emas.ding.zj.gov.cn'
			// 	};
			// 	const wpk = new wpkReporter(config);
			// 	wpk.installAll();
			// 	window._wpk = wpk;
			// } catch (err) {
			// 	console.error('WpkReporter init fail', err);
			// }
			
			// this.createQrCode("https://sydw.app.saiyun.net:443/getUserInfo?data=eyJ1c2VySWQiOiJHRV8wM2NjMGRhMmI0ZTg0N2FiOGRiYTI1MzM4NTgxMmY3MyIsInRpbWVzdGFtcCI6MTYxMDc5Nzc1OX0=");
		},
		methods: {
			refresh() {
				dd.getAuthCode({
					//tenantId租户Id
					corpId: "42420"
				}).then(result => {
					if (result) {
						let authCode = result.code;
						if (authCode == null) {
							authCode = result.auth_code; //pc端返回值
						}
						//获取用户
						this.getUser(authCode);
					} else {
						console.log("get authCode error=" + JSON.stringify(result));
					}
				})
			},
			getUser(authCode) {
				axios.get(this.ctx + "getEmployee?authCode=" + authCode).then(response => {
					const result = response.data;
					if (result.code == 0) {
						const user = result.data;
						//创建二维码
						this.$nextTick(() => {
							this.createQrCode(user.qrCodeContent);
						})
						if(user != null && this.isFirst){
							//流量监控埋点
							this.monitor(user);
						}
					} else {
						console.log("getUser error=" + JSON.stringify(result));
					}
				})
				.catch(error => {
					console.log(error);
				});
			},
			createQrCode(content) {
				//创建前先清空二维码
				this.$refs.qrCodeDiv.innerHTML = ""
				
				new QRCode(this.$refs.qrCodeDiv, {
					text: content,
					width: 200,
					height: 200,
					colorDark: "#000000", //二维码颜色
					colorLight: "#ffffff", //二维码背景色
					correctLevel: QRCode.CorrectLevel.L //容错率，L/M/H
				})
			},
			//流量分析埋点
			monitor(user) {

				//用户信息埋点
				// 如采集用户信息是异步行为需要先执行这个BLOCK埋点
				aplus_queue.push({
					action: 'aplus.setMetaInfo',
					arguments: ['_hold', 'BLOCK']
				});

				//基础埋点
				// 单页应用 或 “单个页面”需异步补充PV日志参数还需进行如下埋点：
				aplus_queue.push({
					action: 'aplus.setMetaInfo',
					arguments: ['aplus-waiting', 'MAN']
				});
				// 单页应用路由切换后 或 在异步获取到pv日志所需的参数后再执行sendPV：
				aplus_queue.push({
					'action': 'aplus.sendPV',
					'arguments': [{
						is_auto: false
					}, {
						// 当前你的应用信息，此两行请勿修改
						sapp_id: '1560',
						sapp_name: 'jgdwfkm',
						// 自定义PV参数key-value键值对（只能是这种平铺的json，不能做多层嵌套），如：
						// 页面ID，与page 参数配合使用，保证唯一性
						page_id: 'organs-canteen-payment-code-001',
						//页面中文名称
						page_name: '机关食堂付款码',
						//页面URL
						page_url: 'https://sydw.app.saiyun.net/'
					}]
				})

				// 设置会员ID
				aplus_queue.push({
					action: "aplus.setMetaInfo",
					arguments: ["_user_id", user.accountId]
				});

				// 设置会员昵称
				aplus_queue.push({
					action: "aplus.setMetaInfo",
					arguments: ["_user_nick", user.nickNameCn]
				});

				// // 如采集用户信息是异步行为，需要先设置完用户信息后再执行这个START埋点
				// // 此时被block住的日志会携带上用户信息逐条发出
				// aplus_queue.push({
				// 	action: 'aplus.setMetaInfo',
				// 	arguments: ['_hold', 'START']
				// });
				
				this.isFirst = false;
			}
		}
	}
</script>
<style>
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}

	.home {
		width: 100vw;
		height: 100vh;
		background: #3296fa;
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.home .qrcode-content {
		width: 80%;
		height: 400px;
		background: #fff;
		position: relative;
		border-radius: 10px;
	}
	
	.home .qrcode-content .qrcode{
		position: absolute;
		top: calc((100vw * 0.8 - 200px) / 2);
		left: 50%;
		transform: translate(-50%, 0);
	}

	.home .qrcode-content a {
		font-size: 20px;
		text-decoration: none;
		color: #3296fa;
		position: absolute;
		bottom: 5vh;
		left: 50%;
		transform: translate(-50%, 0);
		width: 100%;
	}

	.home h1 {
		font-size: 30px;
		color: #fff;
		height: 16vh;
		line-height: 16vh;
	}

	.home p {
		font-size: 20px;
		color: #fff;
		height: 16vh;
		line-height: 16vh;
	}

</style>